import React from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from 'theme/theme'

type LoadingCircleColors = keyof typeof colors
const defaultProps = {
	diameter: 20,
	strokeWidth: 2,
	color: 'white' as LoadingCircleColors,
}

type LoadingCircleProps = typeof defaultProps

const rotate = keyframes`
	100% {
		transform: rotate(360deg);
	}
`

const dash = keyframes`
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
`

const LoadingCircleWrapper = styled.div<{ size: number }>`
	position: relative;
	width: ${props => `${props.size}px`};
	height: ${props => `${props.size}px`};
	margin: 0 auto;
`

const LoadingCircleLoad = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform-origin: center center;
	animation: ${rotate} 2s linear infinite;
`

const LoadingCirclePath = styled.circle<{ color: LoadingCircleColors }>`
	stroke: ${props => props.theme.colors[props.color]};
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -50;
	stroke-linecap: round;
	animation: ${dash} 2s ease-in-out infinite;
`

export const LoadingCircle = ({ diameter, strokeWidth, color, ...props }: LoadingCircleProps) => {
	return (
		<LoadingCircleWrapper size={diameter} {...props}>
			<LoadingCircleLoad viewBox="25 25 50 50">
				<LoadingCirclePath cx="50" cy="50" r="20" fill="none" stroke="#fff" strokeWidth={strokeWidth} color={color} />
			</LoadingCircleLoad>
		</LoadingCircleWrapper>
	)
}

LoadingCircle.defaultProps = defaultProps
