import React from 'react'
import PropTypes from 'prop-types'
import Link, { LinkProps } from 'next/link'
import { removeTrailingSlash } from 'utils/url'
import { useLocationContext } from 'components/location-provider/location-provider'

type NextLinkProps = {
	href: string
	children: PropTypes.ReactElementLike
} & Omit<LinkProps, 'href' | 'as'>

const domain = 'https://www.porto.pt'

export const NextLink = ({ href: baseHref, children, ...props }: NextLinkProps) => {
	const { origin } = useLocationContext()
	const href = baseHref
		.trim()
		.replace('http://porto.pt', domain)
		.replace('http://www.porto.pt', domain)
		.replace(origin, '')
	const isRelativeUrl = href.startsWith('/') && !href.startsWith('/documents/')
	const localHref = isRelativeUrl ? removeTrailingSlash(href) : href

	return isRelativeUrl ? (
		<Link href={localHref} {...props}>
			{children}
		</Link>
	) : (
		React.cloneElement(children, { href, target: '_blank', rel: 'nofollow' })
	)
}

NextLink.propTypes = {
	href: PropTypes.string.isRequired,
	children: PropTypes.element.isRequired,
	replace: PropTypes.bool,
	scroll: PropTypes.bool,
	shallow: PropTypes.bool,
	passHref: PropTypes.bool,
	prefetch: PropTypes.bool,
}
