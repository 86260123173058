import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { truncateText, transition } from 'theme/utils'

export enum TextTypes {
	body = 'body',
	paragraph = 'paragraph',
}
export enum TextFonts {
	primary = 'primary',
	secondary = 'secondary',
}
export enum TextWeights {
	regular = 'regular',
	bold = 'bold',
}
type TextProps = {
	textType?: TextTypes
	font?: TextFonts
	weight?: TextWeights
	maxLines?: number
	title?: string
	dangerouslySetInnerHTML?: { __html: string }
	children?: PropTypes.ReactNodeLike
}

export const Paragraph = styled.p<TextProps>`
	font-family: ${({ font, theme }) => (font === TextFonts.secondary ? theme.fonts.secondary : theme.fonts.primary)};
	font-size: ${props => (props.textType === TextTypes.paragraph ? '16px' : '16px')};
	font-weight: ${props => (props.weight === TextWeights.regular ? 400 : 700)};
	line-height: ${props => (props.textType === TextTypes.paragraph ? '20px' : '24px')};
	color: ${props => props.theme.text.primary};
	transition: ${transition('color')};
	${props => props.maxLines && truncateText(props.maxLines)}
`

export const Text = ({ children, ...props }: TextProps) => <Paragraph {...props}>{children}</Paragraph>

Text.defaultProps = {
	textType: TextTypes.body,
	font: TextFonts.primary,
	weight: TextWeights.regular,
}
